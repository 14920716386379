/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FAVOURITE, SET_FAVOURITE } from "../actions";
import { apiUrl, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetFavouriteproducts = function* () {
  yield takeEvery(GET_FAVOURITE, workerGetFavouriteproducts);
};

function* workerGetFavouriteproducts({ formPayload }) {
  try {
    const result = yield call(getFavouriteProducts, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FAVOURITE, value: resultArr });
  } catch {
    console.log("Get Favourite Products Add Failed");
  }
}

function getFavouriteProducts(formPayload) {
  return Axios.post(
    apiUrl + "products/favourite_products_add",
    formPayload,
    headerconfig
  );
}
